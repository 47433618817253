<template>
    <div class="container">
        <div class="logout-box" v-if="role != 4">
            <div class="logout-box__name1">用户名</div>
            <div class="logout-box__name2">{{name}}</div>
        </div>
        <div class="logout-box" v-if="role == 4">
            <div class="logout-box__name1">机构名称</div>
            <div class="logout-box__name2">{{organization_name}}</div>
        </div>
        <div class="logout-box">
            <div class="logout-box__name1">手机号/账号</div>
            <div class="logout-box__name2">{{username}}</div>
        </div>
        <!-- <div class="logout-box" v-if="role != 4" @click="taping">
            <div class="logout-box__name1">专家他评手册</div>
            <div class="logout-box__name2">
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div class="logout-box" v-if="role == 4" @click="ziping">
            <div class="logout-box__name1">机构自评手册</div>
            <div class="logout-box__name2">
                <i class="el-icon-arrow-right"></i>
            </div>
        </div> -->
        <div class="btn-box">
            <el-button class="login-btn" @click="logout" type="primary" v-throttle>退出登录</el-button>
        </div>
    </div>
    <!-- </div> -->
</template>
<script>

export default {
    name: "Logout",
    data() {
        return {
            name: '',
            username: '',
            role: ''
        };
    },
    computed: {
    },
    components: {
    },
    mounted() {
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        this.organization_name = localStorage.getItem('organization_name')
        this.role = localStorage.getItem('role')
    },
    methods: {
        taping() {
            window.open('https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/files/%E4%B8%93%E5%AE%B6%E7%9D%A3%E5%AF%BC%E4%BB%96%E8%AF%84-%E5%B7%A5%E4%BD%9C%E6%89%8B%E5%86%8C7.5.pdf')
        },
        ziping() {
            window.open('https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/files/%E6%89%98%E8%82%B2%E6%9C%BA%E6%9E%84%E8%87%AA%E8%AF%84-%E5%B7%A5%E4%BD%9C%E6%89%8B%E5%86%8C7.5.pdf')
        },
        logout() {
            localStorage.removeItem("token");
            this.$router.push({path: '/user/login',replace:true})
        }
    },
};
</script>
<style lang="scss">
.container {
    height: 100%;

    .logout-box {
        display: flex;
        padding: 0 12px;
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        border-bottom: 1px solid #EEEEEE;
        align-items: center;
        justify-content: space-between;

        .logout-box__name1 {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
        }
        
        .logout-box__name2 {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
        }
    }

    .btn-box {
        z-index: 100;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 73px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        .login-btn {
            width: 323px;
            font-size: 19px;
            color: #ffffff;
        }
    }

}
</style>

<style lang="scss">
.content-box {
    .el-tabs__header {
        margin-bottom: 1px !important;
    }

    .el-tabs__nav-wrap {
        border-bottom: 1px solid #EEEEEE;
    }

    .el-tabs__header {
        margin-bottom: 22px;
    }

    .el-form-item {
        margin-bottom: 16px;
    }

    .el-tabs__item {
        padding: 0 12px;
        font-size: 15px;
        width: 88px;
        color: #666666;
    }

    .el-tabs__active-bar {
        width: 30px !important;
        height: 4px;
        background: #0083f5;
        border-radius: 2px;
        margin-left: 42px;
    }

    .el-form-item__content {
        display: flex;
    }

    .el-tabs__item.is-active {
        color: #0083F5;
        font-weight: 500;
    }

    .el-tabs__nav-wrap::after {
        display: none;
    }
}
</style>